$(function(){
  var dirRtlFlag = false;
	if ($("html").attr('dir') == 'rtl') {
		dirRtlFlag = true;
	}
  $('.investors__slider').slick({
		dots: false,
		infinite: true,
		speed: 2000,
		slidesToShow: 6,
		slidesToScroll: 1,
		arrows: true,
		autoplay: true,
		autoplaySpeed: 3000,
		rtl: dirRtlFlag,
		responsive: [{
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1
				}
			}, {
				breakpoint: 1100,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});
});